.bg-img{
    background-image: url('../images/screencapture-quosha-ink-vercel-app-2024-07-23-12_22_34.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 767px) {
    .bg-img {
      background-image: url('../images/screencapture-quosha-ink-vercel-app-2024-07-23-12_24_04.png');
      background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }
  }

